exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-about-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/about.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-about-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-brochure-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/brochure.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-brochure-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-coaching-und-beratung-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/coaching-und-beratung.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-coaching-und-beratung-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-contact-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/contact.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-contact-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-data-privacy-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/data-privacy.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-data-privacy-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-eltern-kind-krabbelgruppe-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/eltern-kind-krabbelgruppe.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-eltern-kind-krabbelgruppe-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-gallery-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/gallery.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-gallery-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-imprint-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/imprint.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-imprint-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-jobs-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/jobs.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-jobs-mdx" */),
  "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-social-behaviour-training-mdx": () => import("./../../../src/templates/pages.js?__contentFilePath=/Users/xy/WEBDEV/comp/kids-first-academy/kfa-gatsby/src/mdx-pages/social-behaviour-training.mdx" /* webpackChunkName: "component---src-templates-pages-js-content-file-path-users-xy-webdev-comp-kids-first-academy-kfa-gatsby-src-mdx-pages-social-behaviour-training-mdx" */)
}

